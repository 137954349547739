import request from '@/utils/request'

/**
 * @description: 查询企业类型
 * @param {*} data
 * @return {*}
 */
export function getAllEnterPriseTypeList(param) {
  return request({
    url: '/user/enterprise/getTypeList',
    method: 'GET',
    param,
  })
}

/**
 * @description: 查询入驻企业列表
 * @param {*} params
 * @return {*}
 */
export function getAllEnterPriseList(params) {
  return request({
    url: '/user/admin/enterprise/query',
    method: 'GET',
    params,
  })
}

/**
 * @description: 查询机构入驻列表
 * @return {*}
 */
export function getAllEnterPriseListByOrg(params) {
  return request({
    url: '/user/admin/enterprise/queryList',
    method: 'GET',
    params,
  })
}

/**
 * @description: 机构入驻详情
 * @return {*}
 */

export function enterpriseQueryDetail(enterpriseCode) {
  return request({
    url: `/user/admin/enterprise/queryDetail/${enterpriseCode}`,
    method: 'GET',
  })
}

/**
 * @description: 企业入驻审核
 * @param {*} data
 * @return {*}
 */
export function auditEnterPrise(data) {
  return request({
    url: '/user/admin/enterprise/audit',
    method: 'POST',
    data,
  })
}
/**
 * @description: 删除企业
 * @param {*} data
 * @return {*}
 */
export function delEnterPrise(params) {
  return request({
    url: '/user/admin/enterprise/delEnterprise',
    method: 'DELETE',
    params,
  })
}

/**
 * @description: 查询企业提交订单
 * @param {*} data
 * @return {*}
 */
export function queryOrderEnterprise(params) {
  return request({
    url: '/user/admin/enterprise/order/query',
    method: 'GET',
    params,
  })
}

/**
 * @description: 查询订单详情
 * @return {*}
 */
export function queryOrderEnterpriseDetail(orderNo) {
  return request({
    url: `/user/admin/enterprise/order/queryDetail/${orderNo}`,
    method: 'GET',
  })
}

/**
 * @description: 审核支付状态
 * @param {*} data
 * @return {*}
 */
export function updatePayStatus(data) {
  return request({
    url: '/user/admin/enterprise/order/updatePayStatus',
    method: 'POST',
    data,
  })
}

/**
 * @description: 更新到期时间
 * @param {*} data
 * @return {*}
 */
export function updateExpireTime(data) {
  return request({
    url: '/user/admin/enterprise/order/updateExpireTime',
    method: 'POST',
    data,
  })
}

/**
 * @description: 查询更新到期时间记录
 * @param {*} data
 * @return {*}
 */
export function getOrderExpireTimeHistory(data) {
  return request({
    url: '/user/admin/enterprise/order/getOrderExpireTimeHistory',
    method: 'POST',
    data,
  })
}

export function getStaffEnterpriseList() {
  return request({
    url: '/user/admin/enterprise/getStaffEnterpriseList',
    method: 'GET',
  })
}

/**
 * @description: 获取企业用户来源
 * @param {*} params
 * @return {*}
 */
export function enterPriseUserQueryUserSource(params) {
  return request({
    url: '/user/admin/enterprise/management/queryUserSource',
    method: 'GET',
    params,
  })
}

/**
 * @description: 获取企业用户来源
 * @param {*} params
 * @return {*}
 */
export function enterPriseUserQueryUser(data) {
  return request({
    url: '/user/admin/enterprise/management/query/user',
    method: 'POST',
    data,
  })
}
/**
 * @description: 查询企业用户详情
 * @param {*} params
 * @return {*}
 */
export function enterPriseUserQueryUserDetail(params) {
  return request({
    url: '/user/admin/enterprise/management/query/userDetail',
    method: 'GET',
    params,
  })
}

/**
 * @description: 查询微信公众号配置
 * @param {*} params
 * @return {*}
 */
export function enterPriseUserQueryWechatConfig(params) {
  return request({
    url: '/user/admin/enterprise/management/query/wechat/config',
    method: 'GET',
    params,
  })
}

/**
 * @description: 录入微信公众号配置
 * @param {*} data
 * @return {*}
 */
export function enterPriseUserSaveWechatConfig(data) {
  return request({
    url: '/user/admin/enterprise/management/save/wechat/config',
    method: 'POST',
    data,
  })
}
/**
 * @description: 更新微信公众号配置
 * @param {*} data
 * @return {*}
 */
export function enterPriseUserUpdateWechatConfig(data) {
  return request({
    url: '/user/admin/enterprise/management/update/wechat/config',
    method: 'POST',
    data,
  })
}

/**
 * @description: 同步公众号粉丝
 * @param {*} data
 * @return {*}
 */
export function enterPriseUserSaveWxUser(data) {
  return request({
    url: '/user/admin/enterprise/management/saveWxUser',
    method: 'POST',
    data,
  })
}

export function enterPriseUserQueryUserDetailAttend(params) {
  return request({
    url: '/user/admin/enterprise/management/query/userDetail/attend',
    method: 'GET',
    params,
  })
}

export function enterPriseUserUpdateRemark(params) {
  return request({
    url: '/user/admin/enterprise/management/update/remark',
    method: 'GET',
    params,
  })
}

/**
 * @description: 通过名称查询机构列表
 * @return {*}
 */
export function getAllEnterpriseListByName(params) {
  return request({
    url: '/user/admin/enterprise/getEnterpriseList',
    method: 'GET',
    params,
  })
}

/**
 * @description: 查询机构入驻渠道审核列表
 * @return {*}
 */
export function getChannelList(params) {
  return request({
    url: '/user/admin/enterprise/management/channel/query',
    method: 'GET',
    params,
  })
}
